import React, { useState } from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout/layout"
import Meta from "../components/addon/meta"
import PageHeader from "../components/pageSections/pageHeader"
import ParagraphText from "../components/pageSections/paragraphText"
import TitleWithDescription from "../components/pageSections/titleWithDescription"
import QuotedText from "../components/pageSections/quotedText"


const AboutFounder = (props) => {
    const { data } = props
    let pageTitle = ''
    let pageInfo = {}
    let components = []
    let metaTags = []
    let canonicalUrl = {}
    let altText = ""
    let imageSrc = ""
    const defaultImageSrc = "https://via.placeholder.com/241x362"
    if (data.allNodeTemplatePages && data.allNodeTemplatePages.edges) {
        pageInfo = data.allNodeTemplatePages.edges[0]
        pageTitle = pageInfo?.node?.title ? pageInfo.node.title : ''
        components = pageInfo?.node?.relationships?.components ? pageInfo.node.relationships.components : []
        metaTags = pageInfo?.node?.metatags ? pageInfo.node.metatags : []
        canonicalUrl = pageInfo?.node?.metatags_on_page || {}
        imageSrc = components && components?.[1] ? components[1].relationships?.field_image?.uri?.url : ''
        altText = components[1]?.image?.alt || "Hinduja Hospital"
    }
   
    return (
        <Layout>
            <Meta
                files={
                    {
                        js: [],
                        css: ["/assets/css/about.css"]
                    }
                }
                tags={{metaTags,canonicalUrl}}
            />
            <main className="innerpage">
                <section className="section_bg pt-first-section pb-section">
                    <div className="container">
                        <div className="section-head mb-0">
                            <PageHeader
                                title={pageTitle}
                            />
                            {
                                (components && components[0]) &&
                                <ParagraphText
                                    nocol={true}
                                    content={components[0]}
                                />
                            }
                        </div>
                    </div>
                </section>
                <section className="section_bg plus_icon about_section about_section_inner">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4 col-4">
                                <figure className="about_img">
                                    {
                                        imageSrc ?
                                            <img
                                                src={imageSrc}
                                                alt={altText}
                                            />
                                            : <img className="lazyload"
                                                src={defaultImageSrc}
                                                alt={altText} />
                                    }
                                </figure>
                            </div>
                            <div className="col-md-8 col-8">
                                <div className="about_content">
                                    {
                                        (components && components[2]) &&
                                        <TitleWithDescription
                                            content={components[2]}
                                            head={2}
                                        />
                                    }
                                    <div className="full_onmobile">
                                        {
                                            (components && components[3]) &&
                                            <QuotedText
                                                content={components[3]?.quote ? components[3].quote : ''}
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="btm_para">
                            {
                                (components && components[4]) &&
                                <ParagraphText
                                    content={components[4]}
                                />
                            }
                        </div>
                    </div>
                </section>
            </main>
        </Layout>
    )
}
export const query = graphql`
query aboutFounderPage {
allNodeTemplatePages(filter: {path: {alias: {regex: "/about-founder$/"}}}) {
edges {
node {
id
title
path {
alias
}
metatags: metatag_normalized {
attributes {
content
name
property
}
}
metatags_on_page: field_meta_tags{
    canonical_url
  }
relationships {
components: field_component_type {
__typename
...ParagraphTitleAndDescription
...ParagraphInset
...ParagraphHTMLText
...ParagraphImageComponent
...ParagraphQuote
...ParagraphImageWithTitleAndDescription
...ParagraphQuote2
...ParagraphImageText
... on paragraph__image_component {
id
relationships {
field_image {
    uri {
        value
        url
    }
}
}
}
}
}
}
}
}
}
`
export default AboutFounder